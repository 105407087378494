import Slider from "react-slick/lib/slider"
import ContainerMain from "src/pages/common/ContainerMain"
import prevSliderIcon from "images/prev_slider_incon_gray.svg"
import nextSliderIcon from "images/next_slider_incon_gray.svg"
import "./HumanOfOnPointV2.scss"
import NguyenVanLam from "images/Company/Career/company-2-nguyen-van-lam.png"
import NguyenVuDuyPhong from "images/Company/Career/company-2-nguyen-vu-duy-phong.png"
import HuaThanhNha from "images/Company/Career/company-2-hua-thanh-nha.png"
import DoanNguyenDaHuong from "images/Company/Career/company-2-doan-nguyen-da-huong.png"
import NguyenLeHung from "images/Company/Career/company-2-nguyen-le-hung.png"
import { useFormatter } from "helpers/i18n"

export default function HumanOfOnPointV2() {
  const { __ } = useFormatter()
  const humanOfOnPointData = [
    {
      image: NguyenVuDuyPhong,
      name: `${__({
        defaultMessage: "Nguyen Vu Duy Phong",
      })}`,
      title: `${__({
        defaultMessage: "Graphic Design Team Leader since 2018",
      })}`,
      text: `${__({
        defaultMessage:
          "When working at OnPoint, I can boost my creativity, try new things and learn a lot from my colleagues.",
      })}`,
      fontSize: 24,
    },
    {
      image: HuaThanhNha,
      name: `${__({
        defaultMessage: "Hua Thanh Nha",
      })}`,
      title: `${__({
        defaultMessage: "Senior Growth Manager since 2017",
      })}`,
      text: `${__({
        defaultMessage:
          "At OnPoint, I have gone through many ups and downs and experienced day- by-day not only the challenges of working within a start-up but also within this fast-paced e-commerce industry. In the end, difficulties are made to be overcome and it is whole-heartedly rewarding to see OnPoint hitting each milestone and attain its deserved success with a constant and ever uncompromised high spirit and motivation of the team!",
      })}`,
      fontSize: 22,
    },
    {
      image: DoanNguyenDaHuong,
      name: `${__({
        defaultMessage: "Doan Nguyen Da Huong",
      })}`,
      title: `${__({
        defaultMessage: "Commercial Director since 2019",
      })}`,
      text: `${__({
        defaultMessage:
          'My 3 words to describe OnPoint would be “value", “growth" and “commitment". "Value" are the long-term values, ensuring 3-winning  relationships for: partner, end-consumer and OnPoint. "Growth" refers to our innovation and continuous development. All ideas, activities and contributions will never come true without our uncompromised “commitment" to pursuit our goals to the end.',
      })}`,
      fontSize: 22,
    },
    {
      image: NguyenLeHung,
      name: `${__({
        defaultMessage: "Nguyen Le Hung",
      })}`,
      title: `${__({
        defaultMessage: "Head of Strategy since 2017",
      })}`,
      text: `${__({
        defaultMessage:
          "There are multiple things that really fascinate me about OnPoint. From the top of my mind, it is the satisfaction achieved when I see how my work can create value to the consumers, while also contribute to the success of the brands and our business partners. Furthermore, my colleagues at OnPoint are a team of highly talented, smart, gritty but also humble people, who are eager to learning, building and sharing. They bring in an energy that reinvigorate me every day. Lastly, the vision of OnPoint, to become an ecosystem that enables millions of online businesses in Southeast Asia, gives each of us the opportunity to challenge our limits with a non-exhaustive desire for exploration and self-improvement.",
      })}`,
      fontSize: 20,
    },
  ]

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: "linear",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  return (
    <>
      <div className="human-of-onpoint-v2">
        <div className="section-title header">
          {__({ defaultMessage: "Humans of OnPoint" })}
        </div>
        <div className="bdkTtOROci">
          <div />
          <div className="RAiNllHuyS-humand-of-onpoint">
            <div className="col-empty left">
              <div className="row-empty"></div>
              <div className="background-div"></div>
            </div>
            <div className="item-v2-section">
              <Slider className="uSDdEFzHYz" {...settings}>
                {humanOfOnPointData?.map((item, index) => {
                  return <Item1 {...item} key={index} />
                })}
              </Slider>
            </div>
            <div className="col-empty right">
              <div className="row-empty"></div>
              <div className="background-div"></div>
            </div>
          </div>
        </div>
        <div className="mobile-display">
          {humanOfOnPointData?.map((item, idx) => {
            const index = (idx % 2) + 1
            const data = { ...item, index }
            return <ItemInMobile {...data} key={index} />
          })}
        </div>
      </div>
    </>
  )
}

function Item1({ image, name, title, text, fontSize }) {
  const lineHeight = fontSize + 4
  return (
    <div className="human-of-onpoint-item-v2">
      <div className="row-empty"></div>
      <div className="item-employee">
        <div className="item-employee-avatar">
          <img alt="" className="ShCDnSURBJ" src={image} />
        </div>
        <div className="employee-name">{name}</div>
        <div className="employee-title">{title}</div>
      </div>
      <div className="item-v2-container">
        <div className="item-text">
          <div className="item-quote">
            <i
              className="text"
              style={{
                fontSize: fontSize,
                lineHeight: `${lineHeight}px`,
              }}
            >
              {text}
            </i>
          </div>
        </div>
      </div>
    </div>
  )
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={nextSliderIcon}
      className={className}
      style={{ ...style, display: "block", color: "#777777", fontSize: 14 }}
      onClick={onClick}
    />
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <img
      src={prevSliderIcon}
      className={className}
      style={{ ...style, display: "block", color: "#777777", fontSize: 14 }}
      onClick={onClick}
    />
  )
}

function ItemInMobile({ image, name, title, text, index }) {
  return (
    <div className={`item-in-mobile item-in-mobile-${index}`}>
      <div className="item-in-mobile__avatar">
        <div className="avatar__image">
          <img alt="" className="ShCDnSURBJ" src={image} />
        </div>
        <div className="avatar__name">{name}</div>
        <div className="avatar__title">{title}</div>
      </div>
      <div className="item-in-mobile__line">
        <div className="item-in-mobile__line__line"></div>
      </div>
      <div className="item-in-mobile__text">{text}</div>
    </div>
  )
}
